import React, { Component } from 'react';
import "./css/typing.css";
import "./js/type_writer";

class Typing extends Component {
    render() {
        return (
            <span className="txt-type" data-wait="3000" data-words='["Hello!", "For a while it will be work in progress.", "Thank you."]'></span>
        )
    }
}
export default Typing;
