import React from 'react';
import Intro from "./components/Intro";
import "./css/main.css";

function App() {
  return (
    <div id="app">
      <Intro />
    </div>
  );
}
export default App;