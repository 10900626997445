let entry = null;
setTimeout(() => {
    entry = document.getElementById("entry");
    // console.log(entry)
    let opacity = 0;
    entry.style.visibility = "visible";
    let timer = setInterval(() => {
        opacity += 1;
        // console.log(opacity);
        entry.style.opacity = opacity / 100;
        if (opacity >= 60) {
            clearInterval(timer);
        }
    }, 50)
}, 5700)
