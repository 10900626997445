import React, { Component } from 'react'
import './css/intro.css';
import 'w3-css/w3.css';
import Particles from 'react-particles-js';
import particles from "../assets/particles.json";
import Typing from "./Typing";
import Entry from "./Entry";

class Intro extends Component {
    render() {
        return (
            <div id="intro">
                <Particles params={ particles } />
                <div className="container" align="center">
                    <div className="w3-animate-opacity w3-text-white">
                        <div className="w3-display-middle App-logo">
                            <h1 className="w3-jumbo w3-animate-top"><Typing /></h1>
                            <hr className="w3-border-grey" style= {{ margin:"auto", width:"40%" }} />
                            <p className="w3-large w3-center"><Entry /></p>
                        </div>
                        {/* <div className="w3-display-bottommiddle w3-padding-large">
                            efefurkankarakaya.github.io
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}
export default Intro;