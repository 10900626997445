import React, { Component } from 'react'
import "./js/manage_entry";

class Entry extends Component {
    render() {
        return (
          <button id="entry" className="w3-button w3-ripple" style={{ opacity: 0, visibility: "hidden" }}>Continue [Disabled]</button>
        )
    }
}
export default Entry;
